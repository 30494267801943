.form-select-input {
  .form-select-input-toggle {
    display: flex;
    align-items: center;

    height: 42px;
    padding: 0.75rem;

    cursor: pointer;

    background-color: white;

    color: #1b2a42;
    font-weight: 500;

    border: 1px solid #a9bec233;
    border-radius: 8px;

    > span {
      position: relative;
      top: 1px;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.2px;
      flex-grow: 1;
    }

    &:hover:not(.disabled) {
      background-color: white;
      outline: 1px solid $primary;
    }

    &:focus {
      background-color: white;
      outline: 1px solid $primary;
    }

    &.active {
      color: $primary;
      background-color: #d1e6ea !important;
      border: 0.5px solid $primary;
    }

    &.disabled {
      background-color: #ededed !important;
      color: #cdcdcd;
    }
  }

  .form-select-input-menu {
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    background-color: white;

    padding: 0.5rem;

    min-width: 140px;

    .options {
      max-height: 500px;
      overflow-y: scroll;
      flex-grow: 2;
      padding: 0.5rem;
    }

    > div {
      > p {
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 0.25rem;
      }

      > a {
        border-radius: 8px;
        padding: 0px;

        &:active,
        &.active {
          color: $primary !important;
          background-color: $secondary !important;
        }

        &:not(:last-child) {
          margin-bottom: 0.75rem;
        }

        label {
          text-transform: uppercase;
          font-weight: 600;
        }

        div:not(:has(label)) {
          padding: 0.5rem 0.75rem;
        }
      }
    }
  }
}
