// Root font Sizes
$root-font-size: 13px; // Root font size for desktop mode
$root-font-size-lg: 13px; // Root font size for tablet mode
$root-font-size-md: 12px; // Root font size for mobile mode

// Page bg
$page-bg: if(isDarkMode(), #151521, white) !default;

// Content border radius
$content-border-radius: 1.5rem !default;

$content-spacing: (
  desktop: 0.5rem,
  tablet-and-mobile: 0.25rem,
) !default;

// Header
$header-config: (
  // Default Mode
  default:
    (
      height: (
        desktop: 65px,
        tablet-and-mobile: 55px,
      ),
      bg-color: if(isDarkMode(), #1e1e2d, $white),
    ),

  // Fixed Mode
  fixed:
    (
      height: (
        desktop: 65px,
        tablet-and-mobile: 55px,
      ),
      z-index: 100,
      bg-color: if(isDarkMode(), #1e1e2d, $white),
      box-shadow:
        if(isDarkMode(), none, 0px 10px 30px 0px rgba(82, 63, 105, 0.05)),
    )
) !default;

// Toolbar
$toolbar-config: (
  z-index: 99,
  height: (
    desktop: 1rem,
    tablet-and-mobile: 0.75rem,
  ),
  bg-color: if(isDarkMode(), darken(#1e1e2d, 2%), $white),
  box-shadow: if(isDarkMode(), none, 0px 10px 30px 0px rgba(82, 63, 105, 0.05)),
  border-top: if(isDarkMode(), 0, 1px solid $border-color),
) !default;

// Aside
$aside-config: (
  z-index: 101,
  transition-speed: 0.3s,
  padding-x: 25px,
  menu-indention: 0.75rem,
  bg-color: white,
  scrollbar-color: $secondary,
  scrollbar-hover-color: $secondary,
  width: 260px,
  minimized-width: 75px,
) !default;

// Footer
$footer-config: (
  bg-color: if(isDarkMode(), #1b1b28, $white),
) !default;
