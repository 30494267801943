.aside {
  display: flex;
  flex-direction: column;
  padding: 0;
  border-right: 1px solid #f2f2f2;
  background-color: white;

  // Custom button
  .btn-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;

    .btn-label {
      transition: opacity get($aside-config, transition-speed) ease;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      opacity: 1;
    }

    .btn-icon {
      display: inline-block;
      transition: opacity get($aside-config, transition-speed) ease;
      overflow: hidden;
      opacity: 0;
      margin: 0;
      width: 0;
    }
  }

  .aside-logo {
    .logo-minimize {
      display: none;
    }
    .button-minimize {
      display: none;
    }
  }

  .aside-label {
    font-size: 11px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #a9bec2;
    margin-bottom: 0.75rem;
  }

  .aside-user {
    padding: 1.25rem 0px;
    display: flex;
    justify-content: center;
  }
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .aside {
    width: get($aside-config, width);
    transition: width get($aside-config, transition-speed) ease;

    // Logo
    .aside-logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: get($header-config, default, height, desktop);
      padding: 1.75rem;
      img {
        cursor: pointer;
      }
    }

    // Aside menu
    .aside-menu,
    .aside-footer {
      width: get($aside-config, width);
    }

    // Fixed aside mode
    .aside-fixed & {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: get($aside-config, z-index);
      overflow: hidden;
    }

    // Static aside mode
    .aside-static & {
      position: relative;
      z-index: 1;
    }

    // Minimize aside mode
    [data-kt-aside-minimize="on"] & {
      width: get($aside-config, minimized-width);
      transition: width get($aside-config, transition-speed) ease;

      // Hoverable
      &.aside-hoverable:hover:not(.animating) {
        transition: width get($aside-config, transition-speed) ease;
        width: get($aside-config, width);
        box-shadow: get($aside-config, minimized-hover-box-shadow);
      }

      .aside-logo {
        .button-expand {
          display: none;
        }
        .button-minimize {
          display: block;
        }
      }

      // Not hovered mode
      &:not(.aside-hoverable),
      &:not(:hover) {
        // Logo
        .aside-logo {
          justify-content: center;

          .logo {
            display: none;
          }
        }

        // Menu
        .aside-menu,
        .aside-footer {
          .menu-title {
            opacity: 0;
            transition: opacity get($aside-config, transition-speed) ease;
          }

          .menu-item.show > .menu-sub {
            height: 0;
            overflow: hidden;
            transition: height get($aside-config, transition-speed) ease;
          }
        }

        .aside-user {
          justify-content: start;
          p {
            display: none;
          }
          * {
            width: 40px !important;
            height: 40px !important;
            min-width: 40px !important;
            min-height: 40px !important;
          }
        }

        .aside-label {
          border-bottom: 1px solid #f2f2f2;
          color: transparent;
        }

        .aside-search {
          display: none;
        }

        // Custom button
        .btn-custom {
          .btn-label {
            width: 0;
            opacity: 0;
            transition: opacity get($aside-config, transition-speed) ease;
          }

          .btn-icon {
            width: auto;
            opacity: 1;
            transition: opacity get($aside-config, transition-speed) ease;
          }
        }
      }
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .aside {
    display: none;

    .aside-logo {
      display: none;
    }
  }
}
