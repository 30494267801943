@for $i from 0 to 11 {
  .pr-#{$i} {
    padding-right: (0.25 * $i) + rem !important;
  }
  .pl-#{$i} {
    padding-left: (0.25 * $i) + rem !important;
  }
  .mr-#{$i} {
    margin-right: (0.25 * $i) + rem !important;
  }
  .ml-#{$i} {
    margin-left: (0.25 * $i) + rem !important;
  }
}

@media (min-width: 992px) {
  @for $i from 0 to 11 {
    .p-lg-#{$i} {
      padding: (0.25 * $i) + rem !important;
    }
    .pr-lg-#{$i} {
      padding-right: (0.25 * $i) + rem !important;
    }
    .pl-lg-#{$i} {
      padding-left: (0.25 * $i) + rem !important;
    }
    .mr-lg-#{$i} {
      margin-right: (0.25 * $i) + rem !important;
    }
    .ml-lg-#{$i} {
      margin-left: (0.25 * $i) + rem !important;
    }
  }
}
