.image {
  box-shadow: 0px 0px 20px 10px rgba(28, 106, 107, 0.08);
  border-radius: 16px;
  width: 100%;
  margin-bottom: 1rem;
  object-fit: cover;
  object-position: top;
  height: 220px;
}

.navLogo {
  height: min-content;
  margin: 0px 2rem 0px 1rem;
}

.description {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: var(--text-secondary);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 1rem;
  > svg {
    margin-bottom: 1px;
    margin-right: 0.25rem;
  }
  span:not(:last-child) {
    margin-right: 1rem;
  }
}

.divText {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
}

.divText + small {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-bottom: 0.5rem;
  height: 50px;
}

.textNewsRelated {
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.note {
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1.4rem;
  font-weight: 500;
  font-size: 13px;
  > a {
    color: initial;
  }

  > a:hover,
  &:hover {
    color: #018d8c;
  }
}
.newsDiv {
  width: 80vw;
  border-right: 1px solid #f2f2f2;
  text-align: justify;

  .news {
    display: flex;
    flex-flow: row wrap;
  }
}

.newsDivPadding {
  padding-right: 140px !important;
}

.news {
  display: flex;
  flex-wrap: wrap;
}

.newsDivContent p {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #404d62;

  a {
    text-decoration: underline !important;
  }
  a:hover {
    text-decoration: underline !important;
  }
  > i {
    font-size: 15px;
    line-height: 23px;
    color: #888b9b;
  }

  > img,
  span > img {
    padding: 0.5rem;
  }
}

.protocolImg {
  padding: 5px;
  width: 32%;
  height: 50px;
  object-fit: scale-down;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
}

.carousel {
  width: 100%;
  margin-bottom: 2.5rem;

  *:not(span) {
    max-height: 450px;
    border-radius: 16px;
  }
}

.carouselText {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  height: 450px;
  width: 100%;
  padding: 2.5rem 2rem;

  color: white;
  background-color: #bcbcbc;
  background-repeat: no-repeat;
  background-size: 100%;

  cursor: pointer;

  > p {
    font-size: 36px;
    font-weight: 600;
    text-align: left;
    max-width: 80%;
    line-height: 40px;
  }

  &:hover > p {
    color: #018d8c !important;
  }

  > span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }
}

.carousel > a {
  width: 7% !important;
  visibility: hidden;
}

.carousel:hover > a {
  visibility: visible;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 2.5rem;
  background-color: #252525;
  color: white;
  width: 100%;

  > div * {
    margin-left: 1rem;
    cursor: pointer;
    color: white;
  }
}

.introduction {
  text-align: center;
  color: black;
  margin-bottom: 2rem;

  > span {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }

  > p {
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.2px;
    color: #000000;
  }

  > small {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #404d62;
  }
}

.btnMostrarMais {
  color: #018d8c;
  background-color: initial;
}

.cardNoticiaSmall {
  background: #ffffff;
  box-shadow: 0px 0px 10px 3px rgba(28, 106, 107, 0.08);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px 10px;
}

.cardNoticiaSmall_image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: fill;
  border-radius: 8px;
}

.cardNoticiaSmall_image div:nth-child(0) {
  width: 50%;
}

.cardNoticiaSmall_text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #1b2a42;
  order: 0;
  flex-grow: 0;
  margin-left: 12px;
}

.cardNoticiaSmall:hover,
.cardNoticia:hover {
  cursor: pointer !important;
}

.cardNoticiaSmall:hover .text,
.cardNoticia:hover .text {
  color: #018d8c;
}

.cardNoticiaSmall:hover .divText + small,
.cardNoticia:hover .divText + small {
  color: #404d62 !important;
}

.cardNoticiaSmall:hover .divText > .svgNoticia,
.cardNoticia:hover .divText > .svgNoticia {
  transform: translate(60%, 0%);
  transition-duration: 500ms;
}

.cardNoticiaSmall .divText > .svgNoticia,
.cardNoticia .divText > .svgNoticia {
  transform: translate(0%, 0%);
  transition-duration: 500ms;
}

.cardNoticiaSmall .description {
  margin-bottom: 0 !important;
}

.containerNoticias {
  max-width: 1300px;
  margin: 0 auto;
  padding-top: 20px;
}

.titulosPaginsDetalhe {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: -0.2px;
  margin-bottom: 1.5rem;
}

.divTextos p,
.textos {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.2px;
  padding-right: 1rem;
  text-align: justify;
}

.newsMainDiv {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-bottom: 1.25rem;
}

.newsMainDiv > div:first-child {
  padding-right: 1.25rem;
}
.newsMainDiv > div:last-child {
  padding-left: 1.25rem;
}

.menus {
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .newsMainDiv > div:first-child {
    border: none !important;
    margin-bottom: 2.5rem;
  }

  .newsMainDiv > div {
    padding: 1.25rem !important;
  }

  .navLogo,
  .menus {
    display: none;
  }

  .name {
    display: none;
  }
}

@media (min-width: 992px) {
  .hamburguer {
    display: none;
  }
}

.Breadcrumb {
  margin: 20px 10px;
}

.newsDetailImage {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: 100% 20%;
  box-shadow: 0px 0px 20px 10px rgba(28, 106, 107, 0.08);
  border-radius: 40px 0px;
}

.newsTitle {
  font-size: 26px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 30px;
  letter-spacing: -0.2px;
  color: #000000;
}

.alertmenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 24px;
  background: #f8fafd;
  border: 1px solid #1465f8;
  box-shadow: 0px 0px 15px rgba(20, 101, 248, 0.15);
  border-radius: 8px;
}

.cardTodayMenu {
  background: rgba(209, 234, 231, 0.25);
  border-radius: 16px;
  margin-top: 40px;
}

.cardMenuTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.cardMenuTitleLeft {
  color: #018d8c;
}

.cardMenuTitleRigth {
  color: #a9bec2;
  font-weight: 500;
}

.cardMenuName {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #66b4b4;
  margin-top: 6px;
  margin-bottom: 8px;
}

.cardMenuPlate {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #404d62;
}

.cardDayMenu {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(28, 106, 107, 0.08);
  border-radius: 8px;
  height: 100% !important;
}

.cardContact {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(28, 106, 107, 0.08);
  border-radius: 8px;
  padding: 20px;
  height: 100%;
}

.cardContact:hover {
  box-shadow: 0px 0px 30px rgba(28, 106, 107, 14%) !important;
}

.cardContactName {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1b2a42;
}

.cardContactDepartment {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #a9bec2;
  margin-top: 5px;
}

.cardContactNumber {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #404d62;
  margin-top: 15px;
}

.cardContactAbreviado {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #1b2a42;
  margin-left: 70px;
}

.protocolImage {
  border-radius: 40px 0px;
  width: inherit;
  min-height: 290px;
  padding: 2.5rem 3rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  margin-bottom: 2.5rem;
}

.protocolImage > img {
  border-radius: 22px 0px;
  /*width: 200px;
  height: 200px;*/
  margin-right: 1.25rem;
  max-width: 220px;
  height: auto;
}

.protocolImage .title {
  font-size: 4rem;
  font-weight: 600;
  line-height: 4.5rem;
}

.protocolTitle {
  color: #1b2a42;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.protocolLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #404d62;
  margin-bottom: 1.25rem;
  cursor: pointer;
}

.protocolCard {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 0px 10px 3px rgba(28, 106, 107, 0.08);
  border-radius: 8px;
  height: 80px;
  padding: 0.5rem;
}

.protocolCard > img {
  width: 65px;
  height: auto;
  background-color: #4d4949;
  border-radius: 6px;
  margin-right: 1.25rem;
  object-fit: scale-down;
  max-height: 60px;
  background-color: #fff;
}

.protocolCard > span {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1b2a42;
}

.eventCard {
  cursor: pointer;
  padding: 1rem;
  box-shadow: 0px 0px 10px 3px rgba(28, 106, 107, 0.04);
  border-radius: 6px;
}

.eventCard > p {
  color: #a9bec2;
}

.horizontesContainer {
  padding-top: 120px;
}

.horizontesImageHeader {
  border-radius: 40px 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  height: 477px;
}

.horizontesTituloHeader {
  font-style: normal;
  font-weight: 600;
  font-size: 68px;
  line-height: 80px;
  letter-spacing: -0.4px;
  color: #1b2a42;
  padding: 3.5rem 0.5rem 0.5rem 0.5rem;
}

.horizontesLabelValue {
  font-style: normal;
  font-weight: 700;
  font-size: 68px;
  line-height: 80px;
  letter-spacing: -0.4px;
  color: #018d8c;
}

.horizontesLabelInfo {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #1b2a42;
  margin-top: 0.5rem;
}

.horizontesSobre {
  max-width: 85%;
}
.horizontesTitulo {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.2px;
  color: #1b2a42;
}

.horizontesP {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #404d62;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.horizontesCard {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(28, 106, 107, 0.15);
  border-radius: 16px;
  height: 235px;
  padding: 2rem;
}

.horizontesCard > h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1b2a42;
}

.horizontesCard > p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #a9bec2;
}

@media (min-width: 576px) {
  .horizontesCard {
    height: 188px;
  }
  .horizontesCard > p {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (min-width: 768px) {
  .horizontesCard {
    height: 240px;
  }
  .horizontesCard > p {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (min-width: 992px) {
  .horizontesCard {
    height: 215px;
  }
  .horizontesCard > p {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (min-width: 1200px) {
  .horizontesCard {
    height: 200px;
  }
  .horizontesCard > p {
    font-size: 14px;
    line-height: 20px;
  }
}

.horizontesBblockquote {
  --accent-color: rgb(247 247 247);
  display: grid;
  margin-top: 100px;
  margin-bottom: 3rem;
}

.horizontesBblockquote > p {
  margin-top: -40px;
}

.horizontesBblockquote::before {
  --qHeight: 6.8rem;
  content: "";
  margin-left: 0;
  margin-top: 0;
  height: var(--qHeight);
  width: calc(var(--qHeight) * 1.1);
  background-image: radial-gradient(
      circle at bottom right,
      transparent calc(var(--qHeight) / 4 - 5px),
      var(--accent-color, black) calc(var(--qHeight) / 4)
        calc(var(--qHeight) / 2),
      transparent calc(var(--qHeight) / 2 + 5px)
    ),
    linear-gradient(var(--accent-color, black), var(--accent-color, black));
  background-size: calc(var(--qHeight) / 2) calc(var(--qHeight) / 2);
  background-position: top left, bottom left;
  background-repeat: space no-repeat;
}

.horizontesCarousel > div > button {
  background-color: #009688 !important;
  width: 8px !important;
  height: 8px !important;
}

.horizontesCarouselText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1b2a42;
  margin-bottom: 3rem;
}

.horizontesCarouselTextPerson {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.horizontesCarouselTextPersonName {
  color: #1b2a42;
}
.horizontesCarouselTextPersonArea {
  color: #a9bec2;
}

.horizontesImageTestemunhos {
  border-radius: 40px 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  height: 470px;
}

.horizontesNoticiasRelacionadas > h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.2px;
  color: #1b2a42;
  margin-bottom: 3rem;
}

.horizontesNoticiasRelacionadas > div {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.horizontesNoticiasRelacionadas > div > div .news {
  display: flex;
  flex-flow: row wrap;
}

.divVideo video {
  border-radius: 16px;
}

.eventContent * {
  margin-bottom: 0px;
  text-align: center;
}

.inputLabel {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #1b2a42;
  margin-bottom: 0.5rem;
}

.gallery-arrows {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: white;
  opacity: 0.5;
  width: 95%;
  top: -250px;
}

.menusDiv {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;
}

.menuCard.noMenu * {
  color: #a9bec2 !important;
}

.menuCard {
  height: 150px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  box-shadow: 0px 0px 30px rgba(28, 106, 107, 0.08);
  border-radius: 16px;

  span {
    background-color: var(--secondary);
    padding: 1.25rem;
    border-radius: 10px;
    width: 60px;
    height: 60px;
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.menuCard p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #09172d;
}

.menuCard:hover {
  cursor: pointer;
  box-shadow: 0px 0px 30px rgba(28, 106, 107, 14%) !important;
}

.cardOrganogramas {
  height: 100px !important;
}
