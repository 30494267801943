.form-radio-input {
  all: unset;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin: 0.25rem;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;

  > input {
    appearance: none !important;
    width: 16px;
    height: 16px;
    text-align: center;
    vertical-align: middle;
    border-radius: 100%;
    color: white;
    margin-right: 7px;
  }

  > input:not(:checked) {
    border: 1px solid $primary;
  }

  > input:disabled {
    border: 1px solid $primary-light;
  }

  > div {
    display: flex;
    flex-grow: 2;
  }

  > div > input {
    all: unset;
    color: #a9bec2;
    text-align: start;
    &:focus {
      color: black;
    }
    width: 100%;
  }

  &:has(:disabled) {
    > span {
      color: #a8a8a8 !important;
    }
  }

  input:checked {
    background-color: $primary !important;
    background-image: url("./circle.svg");
    background-repeat: no-repeat;
    background-position: center;
    &:disabled {
      background-color: $primary-light !important;
    }
  }

  &:has(span):has(:checked) {
    outline: 2px solid #018d8c !important;
  }
}

.form-radio-title {
  border: 1px solid rgba(169, 190, 194, 0.2);
  border-radius: 8px;
  padding: 8px;
  justify-content: start;
}

.form-radio-title:hover {
  border: 1px solid $secondary;
}

.form-circle:hover {
  box-shadow: 0px 0px 3px 3px $secondary !important;
}

.form-radio-title:not(:has(:disabled)):focus-within,
.form-radio-title:not(:has(:disabled)):active {
  outline: 2px solid #096d6d;
}
