//
// Menu Base
//

// Menu group
.menu-group {
  display: flex;
}

.menu,
.menu-wrapper {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.header-line {
  padding: 0px 20px;
}

.header-line > p {
  border-bottom: 1px solid $secondary;
}

// Sub menu
.menu-sub {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: column;
}

.menu-sub-items {
  border-left: 2px solid $secondary;
  padding-left: 0.75rem;
  margin-top: 1rem;
}

// Menu item
.menu-item {
  // Menu Link
  .menu-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    flex: 0 0 100%;
    padding: 9px 8px;
    transition: none;
    outline: none !important;
    border-radius: 8px !important;

    // Menu Icon
    .menu-icon {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: get($menu, link, icon, width);
      margin-right: get($menu, link, icon, space);
      margin-top: -2px !important;
      .svg-icon {
        line-height: 1;
      }
    }

    // Menu Icon
    .menu-bullet {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: get($menu, link, bullet, width);
      margin-right: get($menu, link, bullet, space);
    }

    // Menu Label
    .menu-title {
      display: flex;
      align-items: center;
      flex-grow: 1;
      font-weight: 600;
      font-size: 12px;
    }

    // Menu Label
    .menu-badge {
      flex-shrink: 0;
      margin-left: get($menu, link, badge, space);
    }

    // Menu Arrow
    .menu-arrow {
      font-weight: bold;
      height: fit-content;

      &:after {
        display: block;
        width: 100%;
        content: "+";
        color: #a9bec2;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }

  // Menu Content
  .menu-content {
    padding: get($menu, link, self, padding-y) get($menu, link, self, padding-x);
  }
}

.menu-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

// Accordion arrows
.menu-item {
  &.show {
    .menu-link {
      .menu-arrow:after {
        backface-visibility: hidden;
        transition: get($menu, accordion, arrow-transition);
      }
    }
  }
}

// Center alignment
.menu-center {
  justify-content: center;
}

// Responsive
@each $direction in (up, down) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @if $infix and $direction == down {
      $infix: $infix + "-" + $direction;
    }

    @include media-breakpoint-direction($direction, $breakpoint) {
      // Accordion arrow
      .menu-item.menu#{$infix}-accordion {
        &.showing:not(.menu-dropdown),
        &.show:not(.hiding):not(.menu-dropdown) {
          > .menu-link {
            .menu-arrow:after {
              content: "-";
            }
          }
        }
      }

      // Sub dropdown
      .menu-sub#{$infix}-dropdown {
        display: none;
        border-radius: get($menu, dropdown, border-radius);
        background-color: get($menu, dropdown, background-color);
        box-shadow: get($menu, dropdown, box-shadow);
        z-index: get($menu, dropdown, z-index);

        // Dropdown show
        .show.menu-dropdown > &,
        &.menu.show,
        &.show[data-popper-placement] {
          display: flex;

          // Animation
          @if (get($menu, dropdown, animation) == true) {
            // Move up
            animation: menu-sub-dropdown-animation-fade-in
                #{get($menu, dropdown, animation-speed)}
                ease
                1,
              menu-sub-dropdown-animation-move-up
                #{get($menu, dropdown, animation-speed)}
                ease
                1;

            // Move down
            &[data-popper-placement="top"],
            &[data-popper-placement="top-start"],
            &[data-popper-placement="top-end"] {
              animation: menu-sub-dropdown-animation-fade-in
                  #{get($menu, dropdown, animation-speed)}
                  ease
                  1,
                menu-sub-dropdown-animation-move-down
                  #{get($menu, dropdown, animation-speed)}
                  ease
                  1;
            }
          }
        }
      }

      // Sub accordion
      .menu-sub#{$infix}-accordion {
        display: none;

        .show:not(.menu-dropdown) > &,
        &.show {
          display: flex;
        }
      }

      // Inline
      .menu#{$infix}-inline {
        display: flex;
      }

      // Reset link left & right paddings of level 1 menu links
      .menu#{$infix}-fit {
        > .menu-item {
          > .menu-content,
          > .menu-link {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }
      }

      .menu#{$infix}-column {
        flex-direction: column;
        width: 100%;
      }

      .menu#{$infix}-row {
        flex-direction: row;

        > .menu-item {
          display: flex;
          align-items: center;

          > .menu-link {
            .menu-arrow:after {
              @include menu-link-arrow-rotate(90deg, -90deg);
            }
          }
        }
      }

      // Border radiuses
      .menu#{$infix}-rounded {
        .menu-link {
          @include border-radius($border-radius);
        }
      }

      // Border radiuses
      .menu#{$infix}-pill {
        .menu-link {
          border-radius: 50px;
        }
      }

      // Reset border radiuses
      .menu#{$infix}-rounded-0 {
        .menu-link {
          border-radius: 0 !important;
        }
      }
    }
  }
}

// Menu animations
@keyframes menu-sub-dropdown-animation-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes menu-sub-dropdown-animation-move-up {
  from {
    margin-top: #{get($menu, dropdown, animation-move-offset)};
  }
  to {
    margin-top: 0;
  }
}

@keyframes menu-sub-dropdown-animation-move-down {
  from {
    margin-bottom: #{get($menu, dropdown, animation-move-offset)};
  }
  to {
    margin-bottom: 0;
  }
}
