.button {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.icon {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
