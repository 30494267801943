.modal_header {
  padding: 1.25rem;
  flex-direction: column;
  border: none;
}

.modal_title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.2px;
  color: #1b2a42;
  text-align: center;
}

.modal_subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #404d62;
  margin-top: 8px;
  margin-left: 5rem;
  margin-right: 5rem;
}

.modal_close {
  cursor: pointer;
  color: lightgrey;
  margin-left: 1.25rem;
}

.modal_close:hover {
  color: #000;
  transition-duration: 0.2s;
}

.modal_body {
  padding-top: 0.5rem !important;
  height: auto;
  overflow: auto;
}

.modal_body::-webkit-scrollbar {
  width: 5px;
}

.modal_footer {
  border: none;
  padding-top: 0px !important;
}

@media (max-width: 998px) {
  .modal_body {
    width: 100vw !important;
  }
}
