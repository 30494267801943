.form-check-input {
  all: unset;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;

  input {
    appearance: none !important;
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 3px;
    color: white;
    border: 1px solid $primary;
  }

  input:checked::before {
    content: url("./check-bold.svg");
  }

  input:checked {
    background-color: $primary;
    &:disabled {
      background-color: #d8e9e9;
    }
  }

  &:has(:checked):not(.no-border) {
    outline: 2px solid #018d8c;
  }

  input:disabled {
    border: 1px solid rgba(169, 190, 194, 0.4);
  }

  &:has(:disabled) {
    color: #abaaaa;
  }

  span {
    margin-left: 7px;
  }
}

.form-check-title {
  border: 1px solid rgba(169, 190, 194, 0.2);
  border-radius: 8px;
  padding: 8px !important;
}

.form-check-title:hover {
  border: 1px solid $secondary;
}

.form-checkmark:hover {
  box-shadow: 0px 0px 3px 3px $secondary;
}

.form-check-title:focus-within,
.form-check-title:active {
  outline: 2px solid #096d6d;
}

.no-border {
  border: none !important;
  outline: none !important;
  padding: 0px;
}
