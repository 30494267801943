.card {
  border: none !important;
  box-shadow: none !important;
  border-radius: 8px !important;

  .card-header {
    border-bottom: none !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: unset;
    padding: 5px 0px;

    .card-title {
      color: #1b2a42;
      letter-spacing: -0.2px;
      font-size: large;
      font-weight: 500;
      margin: 0px !important;

      &.flex-column {
        align-items: flex-start;
        justify-content: center;
      }

      small {
        color: var(--kt-text-muted);
        font-size: 1rem;
      }

      .page-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 25px;
        color: #1b2a42;
        letter-spacing: -0.2px;
        margin-bottom: 0.3rem;

        small {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.2px;
          display: block;
          color: #404d62;
          margin-top: 0.5rem;
        }
      }
    }

    .card-title-small {
      display: flex;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      color: #404d62;
    }

    .card-toolbar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
  }
}

.card-shadow {
  box-shadow: 0px 0px 20px 10px rgb(28 106 107 / 3%) !important;
  background: #ffffff !important;
}

.card-border {
  border: 1px solid #a9bec2 !important;
  padding: 1.25rem;
}

.card-border-light {
  border: 1px solid #eff2f5 !important;
  padding: 1.25rem;
}
