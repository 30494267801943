.dashboard-card {
  overflow: hidden;

  background-color: #f2f2f266;
  border: 1px solid #a9bec2;
  color: #404d62;

  &.active {
    background-color: #8cd76914;
    border: 1px solid $success;
    color: $success;
  }

  border-radius: 8px;

  .dashboard-card-body {
    display: flex;
    flex-flow: row nowrap;
    min-height: 70px;
    padding: 1rem;

    .dashboard-card-title {
      width: 30%;
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: -0.2px;
    }

    .dashboard-card-content {
      width: 70%;
      font-size: 16px;
      line-height: 20px;
      text-align: end;
    }
  }

  .dashboard-card-footer {
    background-color: $primary;
    color: white;

    width: 100%;
    padding: 1rem;
    font-weight: 600;

    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      content: url("../../../../../../assets/svg/arrow-right.svg");
      text-align: end;
      height: 22px;
    }
  }
}
