/* ALIGNMENTS */

.text-align-start {
  text-align: start !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}

.vertical-align-top {
  vertical-align: top !important;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
.vertical-align-bottom {
  vertical-align: bottom !important;
}

/* END ALIGNMENTS */

/* BACKGROUNDS */

.bg-none {
  background: none !important;
}
/* BACKGROUNDS */

hr:not([size]) {
  height: 0.5px;
}
