.page-pdc-lista-telefonica .nome {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #1b2a42;
}

.page-pdc-lista-telefonica .depart {
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #a9bec2;
}

.page-pdc-lista-telefonica .telef {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #404d62;
}

.page-pdc-lista-telefonica .subtitulo {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #404d62;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.page-pdc-lista-telefonica .masked-overflow {
  position: fixed;
  bottom: 0;
  height: 60px;
  width: 100%;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 90%
  );
}

.page-pdc-lista-telefonica .favorite-container {
  overflow-x: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;
}

.contacts-table tr td:first-child {
  width: 40px;
  text-align: center;
}

.contacts-table tr td:last-child {
  padding-left: 0.75rem;
}

.contacts-card {
  text-align: center;
  padding: 0.75rem;
  text-align: center;
  box-shadow: 0px 0px 20px 10px #ededed;
  border-radius: 16px;
  cursor: pointer;
}

.contacts-card.selected > div {
  border: 1px solid #ededed !important;
}

.contacts-card.active {
  background-color: var(--primary);
}

.contacts-card span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--primary);
}

.contacts-card.active > span {
  color: white;
}
