.info-card {
  display: flex;
  flex-flow: row nowrap;
  padding: 2.5rem 7.5rem;
  max-width: 1100px;

  .info-card-title {
    width: 350px;
    padding-right: 2.5rem;

    > p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #1b2a42;
      margin-bottom: 0px;
    }
    > small {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color: #1b2a42;
    }
  }
  .info-card-body {
    width: calc(100% - 350px);

    .info-card-item {
      display: flex;
      > svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        position: relative;
        top: 10px;
      }
      > div {
        width: calc(100% - 20px);
        > label {
          font-size: 10px;
          font-weight: 500;
          line-height: 12px;
          letter-spacing: 1px;
          text-align: left;

          color: #9faeb1;
        }
        > p {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
          color: #404d62;
        }
      }
    }
  }
}

@media (max-width: 996px) {
  .info-card {
    flex-flow: row wrap !important;
    padding: 1.25rem 0px !important;
  }
  .info-card-title {
    width: 100% !important;
    padding: 0px 0px 1.25rem 0px !important;
  }
  .info-card-body {
    width: 100% !important;
  }
}
