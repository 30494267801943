.form-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: none;
  padding: 0.25rem 0.5rem;
  line-height: 14px;
  border-radius: 8px;
  border: 0.5px solid #9aa0aa;
  height: 42px !important;

  &:has(textarea) {
    height: auto !important;
  }

  .form-input-lg {
    padding: 0.75rem !important;
  }

  &:hover:not(.disabled) {
    background-color: white !important;
    box-shadow: 0px 0px 3px 3px $primary_light !important;
  }
  &:focus-within:not(.disabled) {
    background-color: white !important;
    box-shadow: 0px 0px 3px 3px $primary_light !important;
    outline: 1px solid $primary !important;
  }
  &.disabled {
    background-color: #f7f8f969 !important;
    > label {
      color: #9faeb1c4 !important;
    }
    svg,
    input {
      color: #9faeb1c4 !important;
    }
  }
}

.form-input > div {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.form-input > label {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #9faeb1;
  text-transform: uppercase;
  width: 100%;
  letter-spacing: 0.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.form-input input,
.form-input textarea {
  border: none !important;
  outline: none !important;
  background-color: initial !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  flex-grow: 2 !important;
  max-width: 100%;
  &:disabled {
    color: #a8a8a8;
  }
  &::placeholder {
    font-size: 12px !important;
  }
}

input::-webkit-calendar-picker-indicator {
  all: unset;
  content: url("./arrow.svg");
}

.form-input textarea {
  width: 100%;
}

.form-input .clear {
  color: #a8a8a8;
  cursor: pointer;
  margin-right: 2px;
}

.form-input.form-input-dark {
  border: none !important;
  background-color: #f7f8f9 !important;
}
