// Aside menu
.aside-menu,
.aside-footer {
  .menu-item {
    .menu-link {
      padding: 0.75rem 0.5rem;

      svg {
        margin-right: 0.5rem;
      }

      &:hover {
        background-color: $secondary;
      }
      * {
        color: #1b2a42;
      }
    }
    .menu-sub-accordion {
      margin-top: 1rem;
      margin-left: 1.25rem;
      padding-left: 0.5rem;
      border-left: 1px solid #a9bec233;
    }
  }
  .menu-accordion.show,
  .menu-accordion.here {
    > .menu-link {
      background-color: $primary !important;
      > .menu-title,
      * {
        color: white !important;
      }
    }
  }
  .menu-link.active {
    background-color: $primary !important;
    * {
      color: white !important;
    }
  }
}
