.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  font-weight: 600;

  > div:first-child,
  > div:last-child {
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: $primary;
    cursor: pointer;

    &:hover:not(.disabled) {
      background-color: $secondary;
    }

    &.disabled {
      color: #d1e6ea !important;
      cursor: unset;
    }
  }

  > div:first-child svg {
    margin-right: 0.25rem;
  }

  > div:last-child svg {
    margin-left: 0.25rem;
  }

  > .pagination-center {
    display: flex;
    flex-wrap: wrap;
    color: #404d62;

    > div {
      cursor: pointer;
      width: 30px;
      height: 30px;
      padding: 0.25rem;
      margin: 0px 0.25rem;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        background-color: $secondary !important;
        color: $primary;
      }

      &:hover {
        background-color: #efefef;
      }
    }
  }
}
