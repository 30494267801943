.btn {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2px;
  padding: 10px 14px !important;
  height: 40px;
  display: flex;
  align-items: center;

  &:disabled {
    background-color: rgba(209, 234, 231, 0.25) !important;
    color: #d1e6ea !important;
  }
}

.btn-slim {
  padding: 6px 10px !important;
}

.btn-primary {
  color: white !important;
  background-color: #018d8c !important;

  &:hover {
    background-color: #096d6d !important;
  }

  &:focus {
    background-color: #018d8c !important;
  }
}

.btn-secondary {
  background-color: rgba(1, 140, 141, 0.1) !important;
  color: #018d8c !important;

  &:hover {
    background-color: #d1e6ea !important;
  }

  &:focus {
    background-color: rgba(1, 140, 141, 0.1) !important;
  }
}

.btn-tertiary {
  background-color: white !important;
  color: #018d8c !important;

  &:hover {
    background-color: #d1e6ea !important;
  }

  &:focus {
    background-color: rgba(1, 140, 141, 0.1) !important;
  }
}

.btn-transparent {
  background-color: transparent !important;
  color: #018d8c !important;

  &:hover {
    background-color: #d1e6ea !important;
  }

  &:focus {
    background-color: rgba(1, 140, 141, 0.1) !important;
  }
}

.btn-primary-danger {
  color: white !important;
  background-color: #f7686a !important;

  &:hover {
    background-color: #e05d5f !important;
  }

  &:focus {
    background-color: #f7686a !important;
  }
  &:disabled {
    background-color: rgba(224, 93, 95, 0.1) !important;
    color: rgba(247, 104, 106, 0.2) !important;
  }
}

.btn-secondary-danger {
  color: rgba(247, 104, 106, 1) !important;
  background-color: rgba(224, 93, 95, 0.2) !important;

  &:hover {
    background-color: rgba(224, 93, 95, 0.3) !important;
    color: rgba(247, 104, 106, 1) !important;
  }

  &:focus {
    background-color: rgba(224, 93, 95, 0.2) !important;
    color: rgba(247, 104, 106, 1) !important;
  }
  &:disabled {
    background-color: rgba(224, 93, 95, 0.1) !important;
    color: rgba(247, 104, 106, 0.2) !important;
  }
}

.btn-tertiary-danger {
  color: rgba(247, 104, 106, 1) !important;
  background-color: white !important;

  &:hover {
    background-color: rgba(224, 93, 95, 0.3) !important;
    color: rgba(247, 104, 106, 1) !important;
  }

  &:focus {
    background-color: rgba(224, 93, 95, 0.2) !important;
    color: rgba(247, 104, 106, 1) !important;
  }
  &:disabled {
    background-color: rgba(224, 93, 95, 0.1) !important;
    color: rgba(247, 104, 106, 0.2) !important;
  }
}
