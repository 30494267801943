.page-notifications.show {
  background: rgba(1, 140, 141, 0.1);
  border-radius: 8px;
}

.page-notifications.show svg {
  color: #018d8c;
}

.page-notifications .dot {
  background: #f7686a;
  box-shadow: 0px 0px 5px #f7686a;
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;
  position: relative;
  top: -8px;
  right: -12px;
}

.page-notifications .title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #404d62;
}

.page-notifications .subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #404d62;
}

.page-notifications .date {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #a9bec2;
}

.page-notifications .dot-list {
  background: #f7686a;
  box-shadow: 0px 0px 5px #f7686a;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: block;
}
