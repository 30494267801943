//BACKGROUND
$background: if(isDarkMode(), white, #1d293d);

// Primary
$primary: #018c8d;
$primary-active: if(isDarkMode(), #187de4, hsl(180, 74%, 6%));
$primary-light: #018c8d20;
$primary-inverse: #ffffff;

// Success
$success: #8cd769;
$success-active: if(isDarkMode(), #04aa77, #47be7d);
$success-light: if(isDarkMode(), #1c3238, #e8fff3);
$success-inverse: #ffffff;

// Info
$info: #5966a4;
$info-active: if(isDarkMode(), #7337ee, #5014d0);
$info-light: if(isDarkMode(), #2f264f, #f8f5ff);
$info-inverse: #ffffff;

// Danger
$danger: #f7686a;
$danger-active: if(isDarkMode(), #ee2d41, #d9214e);
$danger-light: if(isDarkMode(), #3a2434, #fff5f8);
$danger-inverse: #ffffff;

// Warning
$warning: #ffc01f;
$warning-active: if(isDarkMode(), #ee9d01, #f1bc00);
$warning-light: if(isDarkMode(), #392f28, #fff8dd);
$warning-inverse: #ffffff;

//Text
$text: #09172d;
