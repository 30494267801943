:root {
  --primary: #018c8d;
  --secondary: #e8f1f4;

  --text-primary: #1b2a42;
  --text-secondary: #404d62;
  --text-tertiary: #a9bec2;

  --success: #8cd769;
  --warning: #ff8823;
  --danger: #f7686a;
  --info: #1465f8;

  --fc-border-color: #a9bec233;
  --fc-daygrid-event-dot-width: 5px;
}

html,
body {
  font-family: Poppins;
  color: #1e1e1e;
}

#root-select {
  z-index: 10000 !important;
}

.card-star,
.card-trash {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px 10px 0px 0px;
}

.card-star svg > path {
  stroke: rgba(224, 93, 95, 0.2);
}

.card-star svg:hover > path {
  fill: #f7686a65;
}

.card.card-app-container.cards-apps:not(.no-hover):hover
  > .card-star
  svg
  > path {
  stroke: #fc0104;
}

.card-trash svg > path,
.card-trash svg > line {
  stroke: #15010129;
}

.card.card-app-container.cards-apps:not(.no-hover):hover
  > .card-trash
  svg
  > path,
.card.card-app-container.cards-apps:not(.no-hover):hover
  > .card-trash
  svg
  > line {
  stroke: #0000006f;
}

.card-trash svg:hover > path,
.card-trash svg:hover > line {
  stroke: #000000e9 !important;
}

.cards-apps.card-app-container {
  background: #ffffff;
  box-shadow: 0px 0px 20px 10px rgba(28, 106, 107, 3%) !important;
  border-radius: 8px;
  height: 178px;
}

.card.card-app-container.cards-apps:not(.no-hover):hover {
  cursor: pointer;
  box-shadow: 0px 0px 30px rgba(28, 106, 107, 14%) !important;
}

.card.card-app-container.cards-apps.no-hover:hover {
  cursor: initial !important;
}

.cards-apps {
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  padding: 40px 10px 40px 10px;
  gap: 12px;
}

.cards-apps .texto {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #09172d;
}

.cards-apps .icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d1e6ea;
  border-radius: 8px;
  color: #018c8d;
}

@media (max-width: 992px) {
  .modal-rd > .modal-box {
    width: 100vw !important;
  }
}

@media (min-width: 992px) {
  .container-fluid {
    padding: 0px 20px 0px 20px !important;
  }

  .main-row > .container-fluid {
    padding: 0px !important;
  }

  #kt_post .container-fluid {
    margin-bottom: 60px;
  }

  #kt_aside_mobile_toggle {
    display: none !important;
  }
}

@media (max-width: 992px) {
  #kt-language-title {
    display: none;
  }
}

.p-nameuser {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #09172d;
}

.bg-rd {
  background-color: #018c8d !important;
}

.titulo-apps {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #404d62;
}

.label-header-menu {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #a9bec2;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.breadcrumb {
  margin: 0 0 10px 0 !important;
}

.breadcrumb .breadcrumb-item a,
.breadcrumb .breadcrumb-item:last-child,
.breadcrumb .breadcrumb-item {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #a9bec2;
}

.breadcrumb .breadcrumb-item:after {
  content: "/" !important;
  color: #a9bec2;
  font-size: 8px;
}

.breadcrumb .breadcrumb-item a:hover {
  color: #096d6d;
}

.table tbody tr td:last-child {
  border-right: 1px solid #eef2f3;
}

.table tbody tr td:first-child {
  border-left: 1px solid #eef2f3;
}

.table thead tr {
  background: rgba(242, 242, 242, 0.4);
}

.table thead tr th {
  color: #18202e;
  background: rgba(242, 242, 242, 0.4);
  font-style: normal;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  background: #f7f7f76b !important;
}

.table > tbody tr,
.table > tbody td {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #1b2a42;
  border-bottom: 1px solid rgba(169, 190, 194, 0.2);
}

.table tfoot tr:last-child th,
.table tfoot tr:last-child td,
.table tbody tr:last-child th,
.table tbody tr:last-child td {
  border-bottom: 1px solid rgba(169, 190, 194, 0.2);
}

.table .td-detalhes-expandable {
  padding: 0 !important;
}

.table .td-detalhes-expandable .card {
  border-radius: 0;
}

.table .td-detalhes-expandable .table {
  padding: 0;
  margin: 0;
  border-radius: 0 !important;
}

.table .td-detalhes-expandable .table thead tr th {
  color: #ffffff !important;
  background: #1b2a42 !important;
  border-radius: 0px !important;
}
.table .td-detalhes-expandable .table tbody tr td {
  background: #f7f8f9;
}

.table .td-detalhes-expandable .table tbody tr:first-child td {
  border-bottom-left-radius: 0 !important;
}

.table .td-detalhes-expandable .table tbody tr:last-child td {
  border-bottom-right-radius: 0 !important;
}

.table .td-detalhes-expandable .table tbody tr td:first-child {
  border-left: 0 !important;
}

.table .td-detalhes-expandable .table tbody tr td:last-child {
  border-right: 0 !important;
}

.div-input-search-table {
  background: #f7f8f9 !important;
  border: 6px solid #f7f8f9 !important;
}

.div-input-search-table:focus-within:not([disabled]) {
  background-color: #f7f8f9 !important;
}

.div-input-search-table:hover:not([disabled]) {
  background-color: #f7f8f9 !important;
  box-shadow: none !important;
}

.bt-0 {
  border-top: 0px !important;
}

.nav-tabs {
  border-bottom: 0px;
}

.nav-tabs .nav-link {
  border-width: 1px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  color: #404d62;
  font-weight: 500;
  margin-bottom: 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #018d8c;
  font-weight: 500;
  background: rgba(1, 140, 141, 0.1);
  border-radius: 8px;
  border-color: #ffffff #ffffff #ffffff;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  color: #018d8c;
  border-color: transparent !important;
  font-weight: 500;
}

select {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  border-color: rgba(169, 190, 194, 0.2);
  border-radius: 8px;
  padding: 5px;
  color: #018d8c;
}

select:focus-visible {
  outline: none;
}

.custom-tit {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1b2a42;
  margin-bottom: 0.2rem;
}

.custom-subtit {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #1b2a42;
}

.label-rd {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: var(--text-tertiary);
  margin-bottom: 0;
}

.form-input-label-rd {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #9faeb1;
  text-transform: uppercase;
  width: 100%;
  letter-spacing: 1px;
}

.input-pesquisar-apps {
  background: #f7f8f9 !important;
  border: initial;
  border-radius: 6px;
}

.input-pesquisar-apps > .form-input {
  border: initial;
}

.input-pesquisar-apps > input {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #404d62;
}

.input-pesquisar-apps > input::placeholder {
  font-size: 11px !important;
}

.container950 {
  max-width: 950px;
}

.container1400 {
  max-width: 1400px;
}

.div-border-rd {
  padding: 0.7rem;
  border: 1px solid rgba(169, 190, 194, 0.2);
  border-radius: 8px;
}

.h50 {
  height: 50px !important;
}

hr {
  color: #f2f2f2;
  opacity: 1;
}

.separator {
  border-bottom: 1px solid #f2f2f2 !important;
}

.vertical-separator {
  border-right: 1px solid #f2f2f2 !important;
}

.bl {
  border-left: 1px solid rgba(169, 190, 194, 0.2) !important;
}

.br {
  border-right: 1px solid rgba(169, 190, 194, 0.2) !important;
}

.btn-rd,
.btn-rd-bg,
.btn-next-rd,
.btn-prev-rd {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #018d8c;
  cursor: pointer;
  vertical-align: middle;
}

.btn-next-rd > svg {
  margin-left: 0.5rem;
}

.btn-prev-rd > svg {
  margin-right: 0.5rem;
}

.btn-rd-bg {
  color: #018d8c;
  background-color: rgba(1, 140, 141, 0.1);
}

.pagination .page-item.active .page-link {
  color: #018d8c;
  background-color: rgba(1, 140, 141, 0.1);
}

.mw-120px {
  max-width: 120px !important;
}

.mw-140px {
  max-width: 140px !important;
}

.mw-150px {
  max-width: 150px !important;
}

.MuiBox-root:focus-visible {
  outline: 0;
}

.text-align-left {
  text-align: left;
}

.btn.btn-slim.show.menu-dropdown {
  background: rgba(1, 140, 141, 0.1);
}

.mr-3-inverse {
  margin-right: -0.75rem !important;
}

/**************************************/

.symbol-initials {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none !important;
}

.pointer {
  cursor: pointer !important;
}

.label.label-sm {
  font-size: 12px;
  padding: 0px 4px 1px 4px;
}

.label-danger {
  background-color: #f3565d;
}

.label-success {
  background-color: #8cd769;
}

.label {
  padding: 1.5px 10px 1.5px 10px;
  margin: 0;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  font-weight: 400;
  width: auto;
}

.label-notification {
  color: white;
  position: relative;
  top: -12px;
  right: 8px;
  border-radius: 100%;
  padding: 5%;
  background-color: #f04632;
  text-align: center;
  vertical-align: middle;
  font-size: 11px;
  min-width: 21px;
}

.label-riopele {
  background-color: #127576;
  color: #fff;
  border-radius: 50%;
}

.MuiTooltip-tooltip {
  font-size: 14px !important;
  background-color: #191919 !important;
  opacity: 100% !important;
}

.MuiTooltip-arrow::before {
  background-color: #191919 !important;
  opacity: 100% !important;
}

.input:disabled {
  background-color: #efefef !important;
}

.dropdownItem {
  cursor: pointer;
  padding: 0.25rem;
}

.dropdownItem:hover {
  background-color: lightgrey;
  transition-duration: 0.2s;
  border-radius: 4px;
}

.underlined {
  text-decoration: underline !important;
}

.alert a {
  color: inherit;
  text-decoration: underline;
}

.alert a:hover {
  text-decoration: underline !important;
  opacity: 0.9;
}

.alert-info {
  color: #1b2a42;
  background: #f8fafd;
  border: 1px solid #1465f8;
  border-radius: 8px;
}

.alert-warning {
  color: #60391c;
  background: #fef0e8;
  border: 1px solid #ff8823;
  border-radius: 8px;
}

.alert.alert-custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 1.5rem 2rem;
}

.alert.alert-custom .alert-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.25rem 0 0;
}

.b-0 {
  border-bottom: 0px !important;
}

.f-r {
  float: right;
}

.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fs-10-i {
  font-size: 10px !important;
}
.fs-11-i {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}

.fs-20 {
  font-size: 20px !important;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-23 {
  font-size: 23px;
}
.fs-25 {
  font-size: 25px;
}
.fs-36 {
  font-size: 36px;
}
.hover {
  cursor: pointer;
}

.tableTeam .click:hover {
  text-decoration: underline;
  font-weight: bold;
}

.popover {
  z-index: 9999;
}

.card-down-badge {
  position: absolute;
  bottom: -10px;
  right: 45%;
  padding: 5px;
}

.card-down-badge-large {
  position: absolute;
  bottom: -10px;
  right: 30%;
  padding: 5px;
}

.nav-tabs.nav-formacoes .nav-link {
  padding-top: 10px;
  padding-bottom: 10px;
}

.uppercase {
  text-transform: uppercase !important;
}

.w-120px {
  width: 120px;
}

.page-subtitle {
  color: #404d62;
  font-size: 12px;
  font-weight: 600;
}

.label-state {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.label-bg-color {
  display: block;
  text-align: center;
}

.div-registo {
  background: #ffffff !important;
  box-shadow: 0px 0px 30px rgba(28, 106, 107, 0.08) !important;
  border-radius: 8px !important;
}

.div-registo .info-sem-registo-selecionado {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  height: 15em;
  padding-left: 20%;
  padding-right: 20%;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #404d62;
}
.div-registo .info-sem-registo-selecionado h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #404d62;
}

.info-sem-registos {
  height: 20em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-sem-registos > * {
  margin-bottom: 1.25rem;
}

.info-sem-registos p {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #a9bec2;
  margin-bottom: 0.25rem;
}

.info-sem-registos small {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #404d62;
}

.filters {
  border: 1px solid #a9bec233;
  border-radius: 8px 8px 0px 0px;
  padding: 0.75rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
}

.text-black:not(:focus):not(:active) {
  color: black !important;
}

.lh-20px {
  line-height: 20px;
}

.accordion-rd .accordion-item {
  border: none;
}
.accordion-rd .accordion-header .accordion-button {
  padding: 0;
  color: #000;
}
.accordion-rd .accordion-header .accordion-button::after {
  width: 1.45rem;
  height: 1.45rem;
  background-size: 1.45rem;
}
.accordion-rd .accordion-header .titulo {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1b2a42;
  margin-bottom: 0.2rem;
}
.accordion-rd .accordion-header .accordion-button:not(.collapsed) {
  color: #000;
  background-color: #fff;
  box-shadow: initial;
}
.accordion-rd .accordion-header .accordion-button:not(.collapsed) > .titulo {
  color: #018d8c;
}
.accordion-rd .subtitulo {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #a9bec2;
  margin-bottom: 0;
}
.accordion-rd .accordion-body {
  padding: 1.5rem 0 0;
}

.input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #9faeb1;
}

.box-shadow {
  box-shadow: 0px 0px 20px 10px rgba(28, 106, 107, 0.08);
}

.fw-semibold {
  font-weight: 500;
}

.popover-body {
  display: flex;
}

.btn-danger {
  color: white !important;
}

.fc-scrollgrid-section-header {
  background-color: #f2f2f266;
}

.fc-scrollgrid-sync-inner {
  padding: 0.5rem 1.25rem;
  text-transform: uppercase;
}

.fc-dayGridMonth-view,
.fc-media-screen {
  box-shadow: 0px 0px 20px 10px rgb(28 106 107 / 3%) !important;
  padding: 1.25rem;
  border-radius: 10px;
}

.fc-header-toolbar {
  text-transform: uppercase;
  padding: 0px 1.25rem;
}

.fc-media-screen {
  padding: 1.25rem 0px 0px 0px;
}

.fc .fc-toolbar-title {
  font-size: 1.25em !important;
}

.fc .fc-button {
  border-color: #ffffff !important;
  border-radius: 0.475rem !important;
}

.fc-button-primary {
  color: white !important;
  background-color: #018d8c !important;
}

.fc-button-primary:hover {
  background-color: #096d6d !important;
}

.fc-button-primary:focus {
  background-color: #018d8c !important;
  border: none !important;
}

.col-label {
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 1px;
  color: #9faeb1;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
}

.fc-event-resizer::after {
  content: "=";
  display: flex;
  justify-content: center;
  color: white;
  position: relative;
  top: -12px;
  font-size: 14px;
  font-weight: 500;
}

.form-check-title.p-tqm {
  padding: 1.25rem !important;
  font-size: 16px;
}

.btn.tqm-f {
  padding: 30px 60px !important;
  font-size: 1.3rem;
}

.modal {
  background: rgba(9, 23, 45, 0.25) !important;
}

.modal,
.modal * {
  transition: none;
}

.modal-dialog {
  top: 0px;

  width: 100vw;
  height: 100vh;
  max-width: unset;
  max-height: 100vh;

  margin: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: fit-content;
  height: auto;
  max-height: 100%;

  border-radius: 8px !important;
  overflow: hidden;
  padding: 0px;
}

.table-btn-icons {
  align-items: center;
  display: flex;
}

.table-btn-icons > div {
  display: block;
  text-align: center;
}

.table-btn-icons > div:nth-child(2n) {
  border-right: 1px solid #edeff1;
  height: 0.9em;
}

.table-btn-icons > div:first-child,
.table-btn-icons > div:last-child {
  flex-grow: 0;
}

.dev_flag {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  background-color: #018c8d;
  color: #fff;
  font-size: 16px;
  font-weight: 600;

  position: fixed;
  top: 0px;

  height: 40px;
  min-width: 200px;
  z-index: 10000;

  top: -9px;
  left: -88px;
  width: 100px;
  rotate: -45deg;
}

.vl {
  border-right: 1px solid rgba(169, 190, 194, 0.2);
  height: 20px !important;
}

.spinner {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.bg-lightgrey {
  background-color: #fafafa !important;
}

.bg-white {
  background-color: white !important;
}

.bg-gray {
  background-color: #eef2f3 !important;
}

.color-primary {
  color: var(--text-primary);
}

.color-secondary {
  color: var(--text-secondary);
}

.color-tertiary {
  color: var(--text-tertiary);
}

.color-white {
  color: white;
}

.back-hover {
  transition: all 0.3s;
}

.back-hover:hover {
  background-color: #eff2f5;
  transition: all 0.3s;
}

.btn-slim-rd {
  padding: 5px 8px !important;
  height: 32px !important;
}

.min-w-52 {
  min-width: 13rem; /* 208px */
}

.min-w-56 {
  min-width: 14rem; /* 224px */
}

.min-w-80 {
  min-width: 20rem; /* 320px */
}

.zTabs .tab-content {
  border: 1px solid #f2f2f2;
}

.zTabs .nav-tabs .nav-link.active,
.zTabs .nav-tabs .nav-item.show .nav-link {
  border-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 2px solid #018d8c;
  border-color: #ffffff #ffffff #018d8c #ffffff !important;
}

/*.form-input {
  line-height: 15px !important;
}*/
