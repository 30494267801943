.Toastify__toast {
  display: flex !important;
  flex-flow: row nowrap !important;
  align-items: center !important;
}

.Toastify__close-button {
  margin: 7px 0px;
  padding: 6px;
  opacity: 1 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #eaf2eb !important;
  border: 1px solid #d0e3d2 !important;
  color: #1d381e !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success svg {
  color: #306f35 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #fbeae9 !important;
  border: 1px solid #edd0ce !important;
  color: #4c100f !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error svg {
  color: #ba3438 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background-color: #f8fafd !important;
  border: 1px solid #1465f8 !important;
  color: #1b2a42 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info svg {
  color: #1465f8 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: #fef0e8 !important;
  border: 1px solid #edd7ca !important;
  color: #60391c !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning svg {
  color: #ff8823 !important;
}
