.form-switch {
  padding: 0 !important;

  .MuiSwitch-track {
    height: 14px;
    opacity: 0.8 !important;
  }
  .MuiSwitch-thumb {
    background-color: $primary !important;
  }
  &:has(:checked) {
    .MuiSwitch-track {
      background-color: $primary !important;
    }
  }
  &:not(:has(:checked)) {
    .MuiSwitch-track {
      background-color: $secondary !important;
    }
    .MuiSwitch-thumb {
      background-color: $secondary !important;
    }
  }
}
